<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <khrs-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <h2 class='text-2xl font-semibold mb-8'>Contact Us</h2>
      <div class='flex flex-col justify-between gap-y-8'>
        <div class='py-4 pl-4 lg:pl-0 pr-4 rounded flex flex-col lg:flex-row items-start border'>
          <img
            src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_society_logo_black.png'
            class='w-2/3 lg:w-1/3 py-4 lg:px-6' />
          <div class='w-full lg:w-2/3 p-2'>
            <h2 class='font-semibold uppercase text-black'>Korean Heart Rhythm Society</h2>
            <div class='my-2 flex flex-col lg:flex-row items-start lg:items-center hidden'>
              <div class='font-semibold w-36' :style='themeTextColorStyle'>
                Phone Number
              </div>
              <div class=''>
                +82-2-318-5416
              </div>
            </div>
            <div class='my-2 flex flex-col lg:flex-row items-start lg:items-center'>
              <div class='font-semibold w-36' :style='themeTextColorStyle'>
                Email
              </div>
              <div class=''>
                <a href='mailto:khrs@k-hrs.org'>khrs@k-hrs.org</a>
              </div>
            </div>
          </div>
        </div>
        <div class='py-4 pl-4 lg:pl-0 pr-4 rounded flex flex-col lg:flex-row items-start border'>
          <img
            src='https://d3r2ol85dktaw0.cloudfront.net/conferences/gbcc2022/intercom_partners.png'
            class='w-2/3 lg:w-1/3 py-4 lg:px-6' />
          <div class='w-full lg:w-2/3 p-2'>
            <h2 class='font-semibold uppercase text-black'>Secretariat for the 14th Annual Scientific Session of the
              KHRS</h2>
            <h5 class='mb-4 text-gray-600 leading-5 text-sm'>INTERCOM is Korea's Leading PCO and global forerunner in
              the MICE industry, posessing over 30 years of know-how.</h5>
            <div class='my-2 flex flex-col lg:flex-row items-start lg:items-center'>
              <div class='font-semibold w-36' :style='themeTextColorStyle'>
                Phone Number
              </div>
              <div class=''>
                +82-2-564-4367
              </div>
            </div>
            <div class='my-2 flex flex-col lg:flex-row items-start lg:items-center'>
              <div class='font-semibold w-36' :style='themeTextColorStyle'>
                Email
              </div>
              <div class=''>
                <a href='mailto:khrs-reg@intercom.co.kr'>khrs-reg@intercom.co.kr</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KhrsAboutPageNavigation from '@/components/khrs-virtual/KhrsAboutPageNavigation.vue'

export default {
  name: 'ContactUs',
  components: {
    KhrsAboutPageNavigation,
  },
  props: [

  ],
  computed: {
    ...mapGetters('events',[
      'themeTextColorStyle',
    ]),
  }
}
</script>
